let TigrisConfig = {
    wysiwygEditor: "tinymce",
    wysiwygTools: {
        colors: true
    },
    charts: {
        colors: [
            { bg: "#1abc9c", bd: "#1abc9c" },
            { bg: "#9b59b6", bd: "#9b59b6" },
            { bg: "#f39c12", bd: "#f39c12" },
            { bg: "#2980b9", bd: "#2980b9" },
            { bg: "#2ecc71", bd: "#2ecc71" }, // Magenta
            { bg: "#e74c3c", bd: "#e74c3c" }, // Orange
            { bg: "#34495e", bd: "#34495e" }, // Water
            { bg: "rgba(143, 68, 173, 1)", bd: "rgba(143, 68, 173, 1)" }, // Violet
            { bg: "rgba(23, 160, 134, 1)", bd: "rgba(23, 160, 134, 1)" }, // Green
            { bg: "rgba(224, 97, 97, 1)", bd: "rgba(224, 97, 97, 1)" }, // Pink
            { bg: "rgba(53, 70, 93, 1)", bd: "rgba(53, 70, 93, 1)" }, // Dark
        ]
    }
};

export { TigrisConfig };