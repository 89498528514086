export default class TigrisFormCollection {

    element
    addButton
    removeButtons
    container
    count

    constructor(element) {
        this.element = element;
        this.addButton = this.element.querySelector('[data-addfield="collection"]');
        this.removeButtons = this.element.querySelectorAll('[data-removefield="collection"]');
        this.container = this.element.querySelector('.form-collection');
        this.count = 0;
    }

    init() {
        if (null !== this.addButton) {
            this.addButton.addEventListener("click", (e) => {
                e.preventDefault()
                this.addField()
            })
        }

        if (null !== this.removeButtons) {
            this.removeButtons.forEach(btn => {
                this.count++
                btn.addEventListener("click", (e) => {
                    e.preventDefault()
                    this.removeField(btn.closest('.collection_widget'))

                })
            })
        }
    }

    addField() {
        let newWidgetStr = this.element.getAttribute('data-prototype');
        const newName = this.element.id + "_" + this.count;
        newWidgetStr = newWidgetStr.replace(/__id__/g, newName);
        newWidgetStr = newWidgetStr.replace(/__name__/g, this.count);
        let newWidget = this.htmlToElement(newWidgetStr);
        this.container.append(newWidget);
        this.count++;
        const removeField = newWidget.querySelector('[data-removefield]');
        removeField.addEventListener('click', (e) => {
            e.preventDefault;
            this.removeField(newWidget);
        });

        let event = new CustomEvent('form-collection:field-added', { detail: { name: newName, id: this.count, element: newWidget } });
        document.dispatchEvent(event);
    }

    removeField(element) {
        element.remove();

        let event = new CustomEvent('form-collection:field-removed', { detail: { element: element } });
        document.dispatchEvent(event);
    }

    htmlToElement(html) {
        let template = document.createElement('template');
        html = html.trim(); // Never return a text node of whitespace as the result
        template.innerHTML = html;
        return template.content.firstChild;
    }
}