<script>
import { Tooltip } from 'bootstrap'
export default {
    delimiters: ["${", "}"],

    props: {
        route: {
            type: [String, null],
            required: false,
            default: null,
        },
        routeParams: {
            type: Object,
            required: false,
            default: () => { return {} },
        },
        type: {
            type: String,
        },
        modal: {
            type: Boolean,
            default: false,
        },
        modalSize: {
            type: String,
            default: "lg",
        },
        filters: {
            type: Object,
            required: false,
            default: () => { return {} },
        },
        selectedItems: {
            type: Array,
            required: false,
            default: () => { return [] },
        },
        target: {
            type: String,
            default: "_self",
        },

        title: {
            type: String,
            required: false,
            default: null,
        },
        dialogComponent: {
            type: String,
            required: false,
            default: null,
        }
    },

    inject: ['addDialog', 'getDialog'],

    data() {
        return {
            href: "",
            typeClass: null,
            dialog: null,
        };
    },

    mounted() {
        if (this.route !== null) {
            this.href = Routing.generate(
                this.route,
                Object.assign(this.routeParams, this.filters)
            );
        }
        if (typeof this.type != "undefined") {
            this.typeClass = "btn btn-" + this.type;
        }

        if (this.title) {
            return new Tooltip(this.$el);
        }
    },

    methods: {
        click(e) {
            if (this.modal) {
                e.preventDefault()
                this.generateUrl()

                let routeParams = Object.assign(this.routeParams, this.filters);
                if (this.selectedItems.length) {
                    let ids = [];

                    for (const selectedItem of this.selectedItems) {
                        ids.push(selectedItem);
                    }
                    routeParams.ids = ids.join(",");
                }
                let dialogParams = { route: this.route, routeParams: routeParams, size: this.modalSize };
                if (this.dialogComponent !== null) {
                    const vueComponent = vueApp.component(this.dialogComponent, false);
                    if (typeof vueComponent !== 'undefined') {

                        dialogParams = { insideComponent: vueComponent, routeParams: routeParams, size: this.modalSize, route: null };
                    } else {
                        console.warn('Component ' + this.dialogComponent + ' not found');
                    }
                }
                this.dialog = this.addDialog(dialogParams);
                this.dialog.onCreated = () => {
                    this.dialog.component.open();
                }

                this.dialog.onFormSaved = () => {
                    this.$emit('formsaved');
                }

                return false;
            }

            return true;
        },

        generateUrl() {
            if (this.route === null) {
                return;
            }
            let params = Object.assign(this.routeParams, this.filters);
            if (this.selectedItems.length) {
                let ids = [];

                for (const selectedItem of this.selectedItems) {
                    ids.push(selectedItem);
                }
                params.ids = ids.join(",");
            }
            this.href = Routing.generate(this.route, params);
        },
    },

    watch: {
        filters: {
            handler(value, oldValue) {
                this.generateUrl();
            },
            deep: true,
        },

        selectedItems: {
            handler(value, oldValue) {
                this.generateUrl();
            },
            deep: true,
        },
    },
};
</script>

<template>
    <a v-bind:href="href" v-on:click="click" :target="target" :class="typeClass"
        :data-no-swup="modal || target !== '_self'" :title="title ? title : ''"
        :data-bs-toggle="title ? 'tooltip' : false">
        <slot></slot>
    </a>
</template>