/**
 * File upload type
 * Manage fileUploadType
 */
import TigrisSortable from './tigris-sortable.js';

export default class TigrisFileUploadType {

    constructor(element) {
        this.fileIds = [];
        this.element = element;
        this.form = this.element.closest('form');

        this.submit = this.form !== null ? this.form.querySelector('[type="submit"]') : document.querySelector('[type="submit"]');

        this.multiple = null != element.getAttribute('multiple');
        this.btnLoad = element.querySelector('.btn-load');
        this.btnDelete = element.querySelector('.btn-delete');
        this.container = element.querySelector('.fileupload-control-container');
        this.previewPrototype = element.querySelector('.prototype');
        this.input = this.element.querySelector('input[type="hidden"]');

        // Single field type 
        this.isSingle = this.element.classList.contains('single');

        if (null != this.previewPrototype) {
            this.previewPrototype.classList.remove('d-none');
            this.previewPrototype.parentNode.removeChild(this.previewPrototype);
        }

        if (_.isUndefined(this.previewPrototype)) {
            this.previewPrototype = document.createElement('div');
            this.previewPrototype.classList.add('prototype', 'col-md-4', 'col-lg-2', 'mb-2');
            this.previewPrototype.innerHTML = '<div class="card h-100"> \
            <button type="button" class="btn btn-sm btn-light btn-remove"> \
            <i class="fa'+ iconStyle + ' fa-times"></i>\
            </button>\
            <div class="card-body preview-content"></div>\
            </div>';
        }

        this.btnRemoveAll = this.element.querySelector('.btn-remove-all');

        if (null != this.btnRemoveAll) {
            let object = this;
            let preview = this.container.querySelector('.preview');
            this.btnRemoveAll.onclick = function () {
                if (null != preview) {
                    while (preview.firstChild) {
                        preview.removeChild(preview.firstChild);
                        object.fileIds = [];
                        object.refreshValue();
                    }
                }
            };
        }

        this.tigrisSortable = null;

    }

    init() {
        if (this.isSingle) {

        } else {
            this.btnLoad.addEventListener('click', () => {
                this.openDialog();
            });
            if (this.multiple) {
                this.initSortable();
                this.initMultiple();
            } else {
                this.initSimple();
            }
        }


        if (null != this.form && this.multiple) {
            document.addEventListener('form-submit', (e) => {
                if (e.detail.element === this.form) {
                    this.refreshValue();
                }
            });
        }
    }

    /**
     * Open select file(s) dialog
     */
    openDialog() {
        Tigris.openFileSelectorDialog().then(dialog => {
            let bsModal = dialog.DOMElement
            dialog.onValid = () => {
                if (this.multiple) {
                    let checkedInputs = bsModal.querySelectorAll('input.select-file:checked');
                    if (checkedInputs.length) {
                        this.loadMultiple(checkedInputs);
                    }
                } else {
                    let checkedInput = bsModal.querySelector('input.select-file:checked');
                    if (null != checkedInput) {
                        this.loadSimple(checkedInput);
                    }
                }

                dialog.close()
            }

        })
    }

    /**
     * Init multiple file upload field 
     */
    initMultiple() {
        let object = this;
        let cards = this.container.querySelectorAll('.card');
        Array.from(cards).forEach(function (card) {
            let id = card.getAttribute('data-id');
            object.fileIds.push(id);
            let btnRemove = card.querySelector('.btn-remove');
            if (null != id) {
                btnRemove.onclick = function () {
                    card.parentNode.remove();
                    object.fileIds.splice(object.fileIds.indexOf(id), 1);
                    object.refreshValue();
                }
            }
        });
    }

    initSimple() {

    }

    /**
     * Make multiple files sortable
     */
    initSortable() {
        this.tigrisSortable = new TigrisSortable(this.container, {
            onSort: () => {
                this.fileIds = [];
                let cards = this.container.querySelectorAll('.card');
                Array.from(cards).forEach((card) => {
                    let id = card.getAttribute('data-id');
                    this.fileIds.push(id);
                });
                this.refreshValue();
            }
        });

    }

    createPreview(type, path, name) {
        let preview = "";
        let figure = document.createElement('figure');
        let figcaption = document.createElement('figcaption');
        figcaption.innerHTML = name;
        figure.append(figcaption);

        if (type == 'image') {
            preview = document.createElement('img');
            preview.setAttribute('src', path);
            preview.setAttribute('path', name);
        } else if (type == 'audio') {
            preview = document.createElement('audio');
            preview.setAttribute('src', path);
            preview.setAttribute('controls', true);
        } else if (type == 'video') {
            preview = document.createElement('video');
            preview.setAttribute('src', path);
            preview.setAttribute('controls', true);
        } else {
            preview = document.createElement('i');
            preview.classList.add('fa' + iconStyle);
            preview.classList.add('fa-file');
            preview.classList.add('fa-4x');
            preview.classList.add('mt-2');
        }

        figure.append(preview);

        return figure;
    }

    loadMultiple(checkedInputs) {
        Array.from(checkedInputs).forEach((element) => {
            let id = element.closest('.file-item').getAttribute('data-id');
            if (this.fileIds.indexOf(id) == -1) {
                let name = element.closest('.file-item').getAttribute('data-name');
                let path = element.closest('.file-item').getAttribute('data-path');
                let type = element.closest('.file-item').getAttribute('data-type');

                let preview = this.previewPrototype.cloneNode(true);
                preview.setAttribute('data-id', id);
                let previewContent = preview.querySelector('.preview-content');
                let btnRemove = preview.querySelector('.btn-remove');

                let previewFile = this.createPreview(type, path, name);
                if (null != previewFile) {
                    previewContent.appendChild(previewFile);
                }

                // Remove element
                btnRemove.onclick = function () {
                    preview.parentNode.removeChild(preview);
                    this.fileIds.splice(this.fileIds.indexOf(id), 1);
                    this.refreshValue();
                };

                this.container.appendChild(preview);
                this.fileIds.push(id);
            }

            if (this.tigrisSortable !== null) {
                this.tigrisSortable.domUpdated();
            }

            element.checked = false;
        });

        this.refreshValue();
    }

    loadSimple(checkedInput) {
        let id = checkedInput.closest('.file-item').getAttribute('data-id');
        let name = checkedInput.closest('.file-item').getAttribute('data-name');
        let path = checkedInput.closest('.file-item').getAttribute('data-path');
        let type = checkedInput.closest('.file-item').getAttribute('data-type');

        let preview = this.container.querySelector('.preview');

        if (null != preview) {
            while (preview.firstChild) {
                preview.removeChild(preview.firstChild);
            }

            let previewFile = this.createPreview(type, path, name);

            if (null != previewFile) {
                preview.appendChild(previewFile);
            }
        }

        this.fileIds = [id];
        this.refreshValue();

        checkedInput.checked = false;
    }

    refreshValue() {
        if (this.multiple) {
            // Remove old input
            let object = this;
            let inputs = this.element.querySelectorAll('input[type="hidden"]');
            Array.from(inputs).forEach(function (element) {
                object.element.removeChild(element);
            });

            this.fileIds.forEach(function (element, index) {
                object.input.remove();
                let input = object.input.cloneNode();
                input.value = element;
                let name = input.getAttribute('name');
                name = name.replace('[]', '[' + index + ']');
                input.setAttribute('name', name);
                object.element.appendChild(input);
            });

        } else {
            this.input.value = (this.fileIds.length > 0) ? this.fileIds[0] : '';
        }
    }

    /**
     * Upload file for single file upload field
     */
    uploadFile() {

    }
}