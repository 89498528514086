<script>
export default {
    props: {
        items: {
            type: Object,
            required: true,
        },

        rootName: {
            type: String,
            required: true,
        },

        itemId: {
            type: Number,
            required: false,
            default: null,
        },

        level: {
            type: Number,
            required: false,
            default: 0,
        },

        selected: {
            type: Number,
            required: false,
            default: null,
        }
    },

    data() {
        return {
            open: false,
        };
    },

    emits: ['open'],

    methods: {
        click(itemId) {
            if (itemId === this.itemId) {
                if (this.selected === this.itemId) {
                    this.open = !this.open
                } else { // If folder not selected, then open it
                    this.open = true
                }

            }

            if (this.open) {
                this.$emit("open", itemId)
            }
        },
    },

    watch: {
        open(newValue) {
            if (newValue) {
                if (this.$parent.open === false) {
                    this.$parent.open = true
                }
            }
        },
        selected: {
            handler(newValue) {
                if (newValue === this.itemId) {
                    this.open = true
                }
            },
            immediate: true
        },
    },
};
</script>

<template>
    <li class="list-tree-item" :class="'level-' + level">
        <div v-on:click="click(itemId)" :class="{ 'selected': selected == itemId }">
            <i :class="'fa' +
        iconStyle +
        ' ' +
        (open ? 'fa-folder-open' : 'fa-folder')
        "></i>
            {{ rootName }}
        </div>

        <ul v-if="items && items.length">
            <list-tree-item v-for="item in items" v-show="open" :key="item.id" :rootName="item.name"
                :items="item.children ?? []" :level="level + 1" :itemId="item.id" v-on:open="click"
                :selected="selected"></list-tree-item>
        </ul>

    </li>
</template>

<style scoped>
.list-tree-item {
    cursor: pointer;
}

.list-tree-item .selected {
    background-color: var(--bs-secondary-bg);
}
</style>