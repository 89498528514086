<script>
export default {
    data() {
        return {
            showedPages : [],
        };
    },
    inject: ['begin', 'countPerPage', 'currentPage', 'changePage', 'pageCount'],
    methods: {
        first(event) {
            event.preventDefault();
            this.changePage(1);
            this.generateShowedPages();
        },

        prev(event) {
            event.preventDefault();
            this.changePage(this.currentPage - 1);
            this.generateShowedPages();
        },

        set(n, event) {
            event.preventDefault();
            this.changePage(n);
            this.generateShowedPages();
        },

        next(event) {
            event.preventDefault();
            this.changePage(this.currentPage + 1);
            this.generateShowedPages();
        },

        last(event) {
            event.preventDefault();
            this.changePage(9999);
            this.generateShowedPages();
        },

        generateShowedPages() {
            this.showedPages = [];
            let i = 0;
            for (
                i = Math.max(1, this.currentPage - 3);
                i <= Math.min(this.pageCount, this.currentPage + 3);
                i++
            ) {
                this.showedPages.push(i);
            }
            if (this.totalCount == 0) {
                this.begin = 0;
            }
        }
    },

    watch: {
        pageCount(newValue) {
            this.showedPages = [];
            let i = 0;
            for (
                i = Math.max(1, this.currentPage - 3);
                i <= Math.min(newValue, this.currentPage + 3);
                i++
            ) {
                this.showedPages.push(i);
            }
        }
    }
};
</script>

<template>
    <ul class="pagination">
        <li class="page-item" :class="{ disabled: currentPage <= 1 }">
            <a class="page-link" href="#" v-on:click="first($event)">
                <span :class="'fa' + iconStyle +' fa-fast-backward'"></span>
            </a>
        </li>
        <li class="page-item" :class="{ disabled: currentPage <= 1 }">
            <a class="page-link" href="#" v-on:click="prev($event)">
                <span :class="'fa' + iconStyle + ' fa-step-backward'"></span>
            </a>
        </li>
        <li
            v-for="page in showedPages"
            v-bind:class="{ active: page == currentPage }"
            class="page-item"
            :key="page"
        >
            <a class="page-link" href="#" v-on:click="set(page, $event)">{{
                page
            }}</a>
        </li>
        <li
            class="page-item"
            :class="{ disabled: currentPage >= pageCount }"
        >
            <a class="page-link" href="#" v-on:click="next">
                <span :class="'fa' + iconStyle + ' fa-step-forward'"></span>
            </a>
        </li>
        <li
            class="page-item"
            :class="{ disabled: currentPage >= pageCount }"
        >
            <a class="page-link" href="#" v-on:click="last($event)">
                <span :class="'fa' + iconStyle + ' fa-fast-forward'"></span>
            </a>
        </li>
    </ul>
</template>