import 'bootstrap';
import 'lodash/lodash.js';
import { Tigris } from '../../vendor/oviglo/tigris-base-bundle/assets/js/tigris-base.js';
import { TigrisConfig } from '../../config/packages/tigris_config'
Tigris.setOptions(TigrisConfig);

import { vueApp } from '../../vendor/oviglo/tigris-base-bundle/assets/js/vue-base-components.js';
global.vueApp = vueApp;

window.addEventListener('load', () => {
    Tigris.init();

    if (typeof (user) !== 'undefined') {
        //Tigris.enableCron();
    }
});