<script>
export default {
    props: {
        isExtended: {
            type: Boolean,
            default: false,
        }
    },

    data() {
        return {
            sideBar: null,
            contentWrapper: null,
            mainMenuBar:null,
            extended: false
        }
    },

    mounted() {
        this.extended = this.isExtended
        this.sideBar = document.querySelector('.main-sidebar')
        this.contentWrapper = document.querySelector('.content-wrapper')
        this.mainMenuBar = document.querySelector('#main-menu-bar')
        this.sideBar.classList.toggle('mini', this.extended)
        this.contentWrapper.classList.toggle('extended', this.extended)
        this.mainMenuBar.classList.toggle('extended', this.extended)
    },
    methods: {
        triggerMenu() {
            this.extended = !this.extended

            this.sideBar.classList.toggle('mini', this.extended)
            this.contentWrapper.classList.toggle('extended', this.extended)
            this.mainMenuBar.classList.toggle('extended', this.extended)

            this.$http.get(Routing.generate('tigris_base_admin_admin_savemenustyle', {menuExtended: this.extended ? 1 : 0})).then(response => {

            })
        }
    }
}
</script>

<template>
    <button id="switch-menu" class="btn btn-primary" v-html="this.$filters.parseIcons('.icon-bars')" @click="triggerMenu"></button>
</template>