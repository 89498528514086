import { Alert } from 'bootstrap'

export default class TigrisAlert {

    constructor() {
        this.element = document.createElement('div');
        this.element.classList.add('alert', 'alert-js', 'alert-dismissible', 'fade', 'in', 'fixed-top', 'mt-5');
        this.element.style.zIndex = 99999;
        this.element.setAttribute('role', 'alert');

        this.closeButton = document.createElement('button');
        this.closeButton.setAttribute('type', 'button');
        this.closeButton.setAttribute('data-bs-dismiss', 'alert');
        this.closeButton.setAttribute('aria-label', 'close');
        this.closeButton.className = 'btn-close';

        this.alert = null;
    }

    show(content, container, replaceContainer) {
        this.element.innerHTML = content;
        this.element.appendChild(this.closeButton);

        if (container === undefined || container === null) {
            container = document.getElementById('alert');
            if (null === container) {
                container = document.body
            }
            container.appendChild(this.element)

        } else if (replaceContainer) {
            this.element.classList.remove('alert-dismissible', 'fade', 'in', 'fixed-top', 'mt-5')
            this.element.querySelector('button').remove()
            container.replaceWith(this.element);
        }

        //this.alert = Alert.getOrCreateInstance(this.element)

        return this;
    }

    create(content, type, container, replaceContainer) {
        switch (type) {
            case 'success':
                return this.success(content, container, replaceContainer);
            case 'warning':
                return this.warning(content, container, replaceContainer);
            case 'info':
                return this.info(content, container, replaceContainer);
            case 'danger':
                return this.danger(content, container, replaceContainer);
            case 'error':
                return this.error(content, container, replaceContainer);
        }

        return this.show(content, container, replaceContainer);
    }

    warning(content, container, replaceContainer) {
        this.element.classList.add('alert-warning');
        this.show(content, container, replaceContainer);

        return this;
    }

    success(content, container, replaceContainer) {
        this.element.classList.add('alert-success');
        this.show(content, container, replaceContainer);

        return this;
    }

    info(content, container, replaceContainer) {
        this.element.classList.add('alert-info');
        this.show(content, container, replaceContainer);

        return this;
    }

    danger(content, container, replaceContainer) {
        this.element.classList.add('alert-danger');
        this.show(content, container, replaceContainer);

        return this;
    }

    error(content, container, replaceContainer) {
        this.danger(content, container, replaceContainer);

        return this;
    }

    close() {
        if (null !== this.alert) {
            this.alert.close();
        }

        return this;
    }

    life(time) {
        setTimeout(() => { this.close(); }, time);

        return this;
    }
}