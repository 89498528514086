<script>
import ButtonAction from './base/ButtonAction.vue'
export default {
    data() {
        return {
            items: [],
            loading: false,
            currentPath: "",
            selected: null,
            isAjax: true,
        };
    },
    inject: ["changeFilter"],
    emits: ['changeFolder'],
    components: { ButtonAction },
    methods: {
        valid() {
            this.$emit('valid')
        },
        cancel() {
            this.$emit('cancel')
        }
    }
}
</script>

<template>
    <div id="file-manager">
        <tigris-data-list route="tigris_base_file_list" :init-filters="{ search: '', type: 'all', folder: null }"
            init-order-by="createdAt" :init-order-reverse="true" :count-per-page="100" :load-on-start="false"
            v-slot="{ entities, filters, loading, loadData, begin, end, totalCount, selectedItems, selectAll, selectItem, currentItem, changeFilter, sort }">
            <div class="card">
                <div class="card-header d-flex justify-content-between">
                    <div></div>
                    <div class="ms-auto">
                        <div class="form-check form-check-inline">
                            <input type="checkbox" class="toggle form-check-input select-all" id="select-all-file"
                                v-on:change="selectAll()">
                            <label class="form-check-label" for="select-all-file"></label>
                        </div>
                        <button-action v-on:formsaved="loadData()" route="tigris_base_file_download_zip"
                            class="btn-sm me-2" type="info" :modal="false" :selected-items="selectedItems"
                            title="Télécharger"><span
                                v-html="$filters.parseIcons('.icon-download')"></span></button-action>
                        <button-action v-on:formsaved="loadData()" route="tigris_base_file_delete" class="btn-sm"
                            type="danger" :modal="true" :selected-items="selectedItems"
                            :title="$filters.trans('btn.delete')">
                            <span v-html="$filters.parseIcons('.icon-trash')"></span>
                        </button-action>
                    </div>
                </div>

                <div class="card-body">
                    <div class="row g-0">
                        <div class="col-5 col-md-4 col-lg-2">
                            <div class="folder-list">
                                <tigris-list-tree route="tigris_base_filefolder_list" v-slot="{ selected, loadTree }"
                                    root-name="Fichiers"
                                    @change-folder="(id) => { if (filters.folder !== id ) changeFilter('folder', id) }">
                                    <div>
                                        <div class="btn-group btn-group-sm folder-tools">
                                            <button-action @formsaved="loadTree(selected)"
                                                route="tigris_base_filefolder_new" :filters="{ parent: selected }"
                                                :modal="true" title="Ajouter" type="outline-secondary">
                                                <span v-html="$filters.parseIcons('.icon-plus')"></span>
                                            </button-action>
                                            <button-action @formsaved="loadTree(selected)"
                                                route="tigris_base_filefolder_edit" :filters="{ id: selected }"
                                                :class="{ disabled: (selected < 1) }" :modal="true" title="Modifier"
                                                type="outline-secondary">
                                                <span v-html="$filters.parseIcons('.icon-pencil-alt')"></span>
                                            </button-action>
                                            <button-action @formsaved="loadTree(null)"
                                                route="tigris_base_filefolder_delete" :filters="{ id: selected }"
                                                :class="{ disabled: (selected < 1) }" :modal="true" title="Supprimer"
                                                type="outline-danger">
                                                <span v-html="$filters.parseIcons('.icon-trash')"></span>
                                            </button-action>
                                        </div>
                                    </div>
                                </tigris-list-tree>
                            </div>
                        </div>

                        <div class="col-7 col-md-8 col-lg-10">
                            <div class="p-1 upload-zone">
                                <file-upload ref="fileUpload" :auto-upload="true" :remove-files-on-complete="true"
                                    label="Ajouter un fichier" @upload-complete="(ids) => loadData(ids)"
                                    :folder="filters.folder ?? null"></file-upload>
                            </div>
                            <div class="filter mb-2">
                                    <div class="d-flex">
                                        <sort-item label="Date" value="createdAt" v-on:change="sort" class="btn d-flex align-items-center"></sort-item>
                                        <sort-item label="Nom" value="name" v-on:change="sort" class="btn d-flex align-items-center"></sort-item>
                                        
                                        <div class="input-group input-group-sm flex-grow-1">
                                            <span v-html="$filters.parseIcons('.icon-search')" class="input-group-text"></span>
                                            <input type="text" class="form-control" placeholder="Rechercher" v-model="filters.search">
                                        </div>
                                        <div class="btn-group btn-group-sm">
                                            <button class="btn btn-secondary" @click="changeFilter('type', 'all')"
                                                :class="{ active: filters.type == 'all' }">Tous</button>
                                            <button class="btn btn-secondary" @click="changeFilter('type', 'image')"
                                                :class="{ active: filters.type == 'image' }" v-html="$filters.parseIcons('.icon-image')"></button>
                                            <button class="btn btn-secondary" @click="changeFilter('type', 'audio')"
                                                :class="{ active: filters.type == 'audio' }" v-html="$filters.parseIcons('.icon-music')"></button>
                                            <button class="btn btn-secondary" @click="changeFilter('type', 'video')"
                                                :class="{ active: filters.type == 'video' }" v-html="$filters.parseIcons('.icon-video')"></button>
                                            <button class="btn btn-secondary" @click="changeFilter('type', 'file')"
                                                :class="{ active: filters.type == 'file' }" v-html="$filters.parseIcons('.icon-file')"></button>
                                        </div>
                                    </div>
                                   
                                </div>
                            <div class="file-list">
                                

                                <div class="col-12 d-flex align-items-center justify-content-center" v-if="loading">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>

                                <div class="file-items" v-if="!loading">
                                    <div draggable="true" class="file-item" v-for="entity in entities"
                                        :class="{ 'current': (entity.id == currentItem) }" :key="entity.id"
                                        :data-name="entity.name" :data-id="entity.id" :data-type="entity.type"
                                        :data-alt="entity.options ? entity.options.alt : entity.name"
                                        :data-size="entity.sizeString" :data-path="'/' + entity.webPath">
                                        <div class="card">
                                            <div class="card-body">
                                                <button-action v-on:formsaved="loadData()"
                                                    route="tigris_base_file_edit" :route-params="{ id: entity.id }"
                                                    type="outline-secondary" :modal="true" class="file-item-edit btn-sm">
                                                        <span v-html="$filters.parseIcons('.icon-pencil')"></span>
                                                    </button-action>
                                                <img class="file-preview w-100"
                                                    :src="'/' + entity.styleWebPath.thumbnail" :alt="entity.name"
                                                    v-if="entity.type == 'image'">
                                                <span v-if="entity.type == 'file'">
                                                    {{ $filters.parseIcons('.icon-file') }}
                                                </span>
                                                <audio controls :src="'/' + entity.webPath" :alt="entity.name"
                                                    v-if="entity.type == 'audio'"></audio>
                                                <video controls :src="'/' + entity.webPath" :alt="entity.name"
                                                    v-if="entity.type == 'video'"></video>
                                            </div>
                                            <div class="card-footer file-label">
                                                <div class="form-check custom-control-inline">
                                                    <input type="checkbox"
                                                        class="toggle form-check-input select-file select-item"
                                                        :id="'select-' + entity.id" :value="entity.id"
                                                        :checked="selectedItems.includes(entity.id)"
                                                        @change="(e) => { selectItem(entity.id, e.target.checked) }">
                                                    <label class="form-check-label" :for="'select-' + entity.id">
                                                        {{ entity.name }}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div class="card-footer">
                    <div class="d-flex justify-content-between align-items-center">
                        <div><em>{{ begin }} à {{ end }} sur {{ totalCount }}</em></div>
                        <pagination></pagination>
                        <div>
                            <button class="btn btn-secondary" @click="() => cancel()"
                                v-text="$filters.trans('btn.cancel')"></button>
                            <button class="btn btn-primary ms-1 ms-1" @click="() => valid()"
                                v-text="$filters.trans('btn.add')"></button>
                        </div>
                    </div>

                </div>
            </div>

        </tigris-data-list>
    </div>

</template>