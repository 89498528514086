<script>
import TigrisEvents from '../js/tigris-events'
import { toRaw } from 'vue';
export default {
    delimiters: ["${", "}"],

    data() {
        return {
            dialogs: [],
        }
    },

    created() {
        document.addEventListener(TigrisEvents.OPEN_DIALOG, (e) => {
            let dialog = this.addDialog(e.detail)
            dialog.onCreated = () => {
                dialog.component.open();
            }
        })
        document.addEventListener(TigrisEvents.CLOSE_DIALOG, (e) => {
            this.removeDialog(e.detail)
        })
    },

    mounted() {
        setTimeout(() => {
            document.dispatchEvent(new CustomEvent(TigrisEvents.VUE_APP_MOUNTED, { detail: { el: this.$el } }))
        }, 10)

    },

    provide() {
        return {
            addDialog: this.addDialog,
            removeDialog: this.removeDialog,
            getDialog: this.getDialog,
        }
    },

    methods: {
        addDialog(dialog) {
            dialog.n = this.dialogs.length;
            dialog.onCreated = () => { };
            dialog.onFormSaved = () => { };
            dialog.close = () => {
                dialog.component.close()
            }
            this.dialogs.push(dialog);

            return dialog;
        },

        dialogCreated(id, component) {
            this.dialogs[id].component = component;
            this.dialogs[id].DOMElement = component.$el
            this.dialogs[id].onCreated();
            if (typeof this.dialogs[id].onLoaded === 'function') {
                this.dialogs[id].onLoaded(toRaw(this.dialogs[id]))
            }
            this.$emit('dialogCreated', this.dialogs['id']);
        },

        removeDialog(id) {
            if (typeof this.dialogs[id] !== 'function') {
                return
            }
            this.dialogs[id].close()
            this.dialogs[id] = null
            this.dialogs.splice(id, 1);
        },

        getDialog(id) {
            return this.dialogs[id] ?? null;
        },

        onDialogFormSaved(dialogId) {
            this.dialogs[dialogId].onFormSaved();
        },

        onDialogValid(dialogId) {
            if (typeof this.dialogs[dialogId].onValid === 'function') {
                this.dialogs[dialogId].onValid()
            }
        },

        onDialogCancel(dialogId) {
            if (typeof this.dialogs[dialogId].onCancel === 'function') {
                this.dialogs[dialogId].onCancel()
            }
        }
    }
}
</script>
