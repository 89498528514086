<script>
import TigrisEvents from '../js/tigris-events'
export default {
    props: {
        initTheme: {
            type: String,
            default: 'light',
        },
        dataClass: {
            type: String,
            required: false,
            default: 'btn btn-primary',
        },
    },

    data() {
        return {
            theme: false,
        }
    },

    mounted() {
        this.theme = this.initTheme
    },

    methods: {
        setTheme(name) {
            this.$http.get(Routing.generate('tigris_base_app_switchtheme', { theme: name })).then(response => {
                const el = document.querySelector('[data-bs-theme]')
                if (null !== el) {
                    this.theme = response.data.theme
                    el.setAttribute('data-bs-theme', this.theme)
                    document.dispatchEvent(new CustomEvent(TigrisEvents.TOGGLE_THEME, { detail: { theme: this.theme } }));
                }
            })
        }
    }
}
</script>

<template>
    <div class="dropdown">
        <button :class="'dropdown-toggle ' + dataClass" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <span v-if="this.theme === 'light'" v-html="$filters.parseIcons('.icon-sun')"></span>
            <span v-if="this.theme === 'dark'" v-html="$filters.parseIcons('.icon-moon')"></span>
        </button>
        <ul class="dropdown-menu dropdown-menu-end">
            <li>
                <a class="dropdown-item" href="#" :class="{ active: this.theme === 'light' }"
                    @click.prevent="setTheme('light')">
                    <span v-html="$filters.parseIcons($filters.trans('theme.light'))"></span>
                </a>
            </li>
            <li>
                <a class="dropdown-item" href="#" :class="{ active: this.theme === 'dark' }"
                    @click.prevent="setTheme('dark')">
                    <span v-html="$filters.parseIcons($filters.trans('theme.dark'))"></span>
                </a>
            </li>
        </ul>
    </div>
</template>