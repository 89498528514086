import TigrisEvents from './tigris-events'
var TigrisWysiwyg = function (element, selector) {
    this.element = element;
    this.selector = (typeof (selector) == 'undefined') ? '.wysiwyg' : selector;
    this.load = () => {
        let editorElements = this.element.querySelectorAll(this.selector);
        editorElements.forEach((element) => {

            let name = Tigris.options.wysiwygEditor;
            let tools = Tigris.options.wysiwygTools;
            element.removeAttribute('required')
            switch (name.toLowerCase()) {
                case 'tinymce':
                    import('./wysiwyg/tinymce.js').then((wysi) => {
                        wysi.init(element, tools);
                    });

                    break;
            }

            document.dispatchEvent(new CustomEvent(TigrisEvents.WYSIWYG_LOADED, { detail: { name: name.toLowerCase() } }))
        });

    };
};

export default TigrisWysiwyg;