<script>
import Chart from "chart.js/auto";
import TigrisEvents from '../js/tigris-events'
import { debounce } from '../ts/utils'

export default {
    delimiters: ["${", "}"],
    props: {
        route: {
            type: String,
            required: true,
        },

        routeParams: {
            type: Object,
            required: false,
        },

        initFilters: {
            type: Object,
            default: function () {
                return {};
            },
        },

        type: {
            type: String,
            default: "bar",
        },

        name: {
            type: String,
            default: "",
        },

        loadAtStart: {
            type: Boolean,
            default: false,
        },

        initTheme: {
            type: String,
            default: 'dark',
        }
    },

    data() {
        return {
            labels: [],
            stats: [],
            filters: {},
            loading: true,
            filterData: {},
            data: {},
            chart: null,
            show: false,
            colors: Tigris.options.charts.colors,
            canvas: null,
            themes: {
                dark: {
                    grid: 'rgba(255, 255, 255, 0.2)',
                    tickColor: 'rgba(255, 255, 255, 0.8)',
                    label: 'rgba(255, 255, 255, 0.8)',
                },
                light: {
                    grid: 'rgba(0, 0, 0, 0.1)',
                    tickColor: 'rgba(0, 0, 0, 0.8)',
                    label: 'rgba(0, 0, 0, 0.8)',
                },
            },
            theme: 'light',
        };
    },

    mounted() {
        this.theme = this.initTheme
        this.canvas = this.$el.querySelector('canvas');
        if (this.name == "") {
            this.loadData();
        }

        document.addEventListener(TigrisEvents.TOGGLE_THEME, (e) => {
            this.theme = e.detail.theme
            if (this.name == "") {
                this.loadData();
            }
        });
    },

    created() {
        this.filters = this.initFilters;
        if (this.loadAtStart) {
            this.loadData();
        }


        document.addEventListener(
            "tigris:chart-load",
            (event) => {
                if (this.show) {
                    return;
                }

                if (this.name == event.detail.name) {
                    this.loadData();
                }
            },
            false
        );
    },

    watch: {
        filters: {
            handler(){
                if (this.show) {
                    this.loadData();
                }
            },
            deep: true,
        },

        theme: {
            handler: function () {
                if (this.show) {
                    this.loadData()
                }
            },
        },
    },

    methods: {
        loadData() {
            this.show = true;
            let url = Routing.generate(this.route, { _format: "json" });
            url += this.getFiltersQuery("&");
            this.loading = true;
            this.$http.get(url).then(
                (response) => {
                    this.labels = response.data.labels;
                    this.stats = response.data.stats;
                    this.loading = false;
                    Tigris.eventManager().trigger(
                        "tigris-chart.loaded",
                        {},
                        {}
                    );
                    this.createChart();
                },
                (response) => {
                    // error callback
                }
            );
        },

        getFiltersQuery(firstChar) {
            firstChar = typeof firstChar == "undefined" ? "?" : firstChar;
            let isFirst = true;
            let q = "";
            for (const key in this.filters) {
                if (Object.hasOwnProperty.call(this.filters, key)) {
                    const val = this.filters[key];
                    q += (isFirst ? firstChar : "&") + key + "=" + val;
                    isFirst = false;
                }
            }

            return q;
        },

        createChart() {
            if (this.chart) {
                this.chart.destroy();
                this.chart = null;
            }
            this.totalCount = 0;

            let datasets = [];
            this.stats.forEach((element, index) => {
                datasets.push({
                    label: this.stats[index].label,
                    borderWidth: 0,
                    backgroundColor: this.colors[index].bg,
                    data: this.stats[index].data,
                });
            });

            let chartData = {
                labels: this.labels,
                datasets: datasets,
            };

            let canvas = this.canvas.getContext("2d");
            this.chart = new Chart(canvas, {
                type: this.type,
                data: chartData,
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        y: {
                            beginAtZero: true,
                            grid: {
                                color: this.themes[this.theme].grid,
                                tickColor: this.themes[this.theme].tickColor,
                            },
                            title: {
                                color: this.themes[this.theme].label,
                            },
                            ticks: {
                                color: this.themes[this.theme].label,
                            }
                        },
                        x: {
                            grid: {
                                color: this.themes[this.theme].grid,
                                tickColor: this.themes[this.theme].tickColor,
                            },
                            title: {
                                color: this.themes[this.theme].label,
                            },
                            ticks: {
                                color: this.themes[this.theme].label,
                            }
                        }
                    },
                    plugins: {
                        legend: {
                            labels: {
                                color: this.themes[this.theme].label,
                            }
                        }
                    },
                },
            });
        },
    },
}
</script>

<template>
    <div>
        <slot :filters="filters"></slot>
    </div>
</template>