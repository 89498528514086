<script>
import { computed } from 'vue'
import { AlertMessage, AlertType } from '../ts/component/alert-message'
import { debounce } from '../ts/utils'

export default {
    delimiters: ["${", "}"],
    props: {
        route: {
            type: String,
            required: true,
        },

        routeParams: {
            type: [Object, Array],
            required: false,
        },

        updateRoute: {
            type: String,
            required: false,
            default: "",
        },

        initFilters: {
            type: [Object, Array],
            required: false,
            default() {
                return {};
            },
        },

        countPerPage: {
            type: Number,
            default: 30,
        },

        moreCount: {
            type: Number,
            required: false,
            default: 5,
        },

        initOrderBy: {
            type: String,
            required: false,
            default: "id",
        },

        initOrderReverse: {
            type: Boolean,
            required: false,
            default: true,
        },

        initBegin: {
            type: Number,
            required: false,
            default: 0,
        },

        sortable: {
            type: String,
            default: "",
        },

        loadOnStart: {
            type: Boolean,
            required: false,
            default: true,
        },
    },

    data() {
        return {
            entities: [],
            onUpdateEntity: false,
            filters: {},
            selectedItems: [],
            currentItem: 0,
            totalCount: 0,
            pageCount: 0,
            currentPage: 1,
            order: "id",
            reverse: true,
            begin: 1,
            end: 1,
            loading: false,
            filterData: {},
            data: {},
            firstLoaded: false,
            entitiesPerPage: 30,
        };
    },
    provide() {
        return {
            entities: this.entities,
            order: computed(() => this.order),
            loading: this.loading,
            filters: this.filters,
            pageCount: computed(() => this.pageCount),
            begin: this.begin,
            end: this.end,
            countPerPage: this.entitiesPerPage,
            changePage: this.changePage,
            currentPage: computed(() => this.currentPage),
            setCountPerPage: this.setCountPerPage,
            selectedItems: this.selectedItems,
            selectAll: this.selectAll,
            setCurrentItem: this.setCurrentItem,
            changeFilter: this.changeFilter,
        }
    },

    created: function () {
        this.filters = this.initFilters;
        this.order = this.initOrderBy;
        this.reverse = this.initOrderReverse;
        this.entitiesPerPage = this.countPerPage;
        this.begin = this.initBegin;
        this.currentPage =
            Math.floor(this.begin / this.entitiesPerPage) + 1;

        if (this.loadOnStart) {
            if (document.readyState === "complete") {
                this.loadData();
            } else {
                window.addEventListener("load", () => {
                    this.loadData();
                });
            }
        } else {
            this.firstLoaded = true;
        }

        document.addEventListener("tigris-data-list:load", (event) => {
            let params = event.detail;
            if (params.countPerPage) {
                this.entitiesPerPage = params.countPerPage;
            }

            if (params.page) {
                this.currentPage = params.page;
            }

            this.loadData();
        });

        this.selectedItems = [];
    },

    computed: {
        reverseEntities() {
            return this.entities ? this.entities.slice().reverse() : [];
        },

        computedEntities() {
            return _.map(this.entities, _.clone);
        },
    },

    watch: {
        filters: {
            handler: debounce(function (value, oldValue) {
                if (!this.firstLoaded) {
                    return;
                }
                this.currentPage = 1;
                this.loadData();
            }, 500),
            deep: true,
        },

        computedEntities: {
            handler: debounce(function (value, oldValue) {
                if (!this.onUpdateEntity) {
                    return;
                }

                this.onUpdateEntity = false;
                // Ignore first time loading
                if (oldValue.length == 0) {
                    return;
                }

                value.forEach((element, index) => {
                    if (!_.isEqual(element, oldValue[index])) {
                        for (const propertyName in element) {
                            if (
                                element[propertyName] !=
                                oldValue[index][propertyName] &&
                                element.id == oldValue[index].id
                            ) {
                                if (this.updateRoute != "") {
                                    let url = Routing.generate(
                                        this.updateRoute,
                                        { id: element.id }
                                    );
                                    this.$http
                                        .put(url, {
                                            data: propertyName,
                                            value: element[propertyName],
                                        })
                                        .then((response) => {

                                            new AlertMessage(
                                                response.data.message,
                                                response.data.status
                                            ).show();
                                        });
                                } else {
                                    new AlertMessage(
                                        "Update route not defined",
                                        AlertType.DANGER
                                    ).show();
                                }
                            }
                        }
                    }
                });
            }, 500),
            deep: true,
        },
    },

    methods: {
        /**
         * @param selectedItems set items as selected (checked)
         */
        loadData(selectedItems = []) {
            this.begin = (this.currentPage - 1) * this.entitiesPerPage + 1;
            let routeParams = {
                _format: "json",
                begin: this.begin - 1,
                count: this.entitiesPerPage,
                order: this.order,
                rev: this.reverse,
            };
            if (this.routeParams) {
                _.assignIn(routeParams, this.routeParams);
            }

            var url = Routing.generate(this.route, routeParams);
            url += this.getFiltersQuery("&");
            this.loading = true;
            this.$http.get(url).then(
                (response) => {
                    this.entities = response.data.entities;
                    this.totalCount = response.data.totalCount;
                    this.pageCount = Math.ceil(
                        this.totalCount / this.entitiesPerPage
                    );
                    this.end = Math.min(
                        this.begin - 1 + this.entitiesPerPage,
                        this.totalCount
                    );
                    if (this.totalCount == 0) {
                        this.begin = 0;
                    }
                    this.loading = false;
                    this.selectedItems = [];
                    if (selectedItems !== null) {
                        this.selectItems(selectedItems);
                    }
                    this.currentItem = 0;
                    this.firstLoaded = true;

                    // Events
                    let event = new CustomEvent("tigris-data-list:loaded", {
                        detail: { element: this.$el },
                    });
                    document.dispatchEvent(event);

                    this.$emit("dataLoaded");
                },
                (response) => {
                    // error callback
                }
            );
        },

        getFiltersQuery(firstChar) {
            firstChar = typeof firstChar == "undefined" ? "?" : firstChar;
            let q = "";
            for (const key in this.filters) {
                if (Object.hasOwnProperty.call(this.filters, key)) {
                    const val = this.filters[key];
                    q += firstChar + key + "=" + val;
                }
            }

            return q;
        },

        sort(order, reverse) {
            if (typeof reverse != "undefined") {
                this.reverse = reverse;
            } else if (this.order == order) {
                this.reverse = !this.reverse;
            } else {
                this.reverse = false;
            }
            this.order = order;
            this.loadData();
        },

        changePage(p) {
            this.currentPage = Math.max(1, Math.min(this.pageCount, p));
            this.loadData();

            return this.currentPage;
        },

        action(href) {
            TigrisDialog.open(href, (formData, data) => {
                this.loadData();
                Tigris.eventManager().trigger(
                    Tigris.EVENT_FORM_POST,
                    { source: "tigris_data_list" },
                    { formData: formData, response: data }
                );
            });
        },

        loadFilterData(
            filterDataName,
            route,
            routeParams,
            filterName,
            filterValue
        ) {
            if (typeof filterValue === "undefined") {
                filterValue = "id";
            }
            let url = Routing.generate(
                route,
                Object.assign({ _format: "json" }, routeParams)
            );
            this.filterData[filterDataName] = {};
            this.$http.get(url).then(
                (response) => {
                    this.filterData[filterDataName] = response.data;
                    if (response.data.length > 0) {
                        this.filters[filterName] = "";
                    } else {
                        this.filters[filterName] = null;
                    }
                },
                (response) => {
                    this.filters[filterName] = "";
                }
            );
        },

        changeFilter(data, value) {
            if (arguments.length === 2) {
                this.filters[data] = value;
            } else {
                this.filters[data.filterName] = data.filterValue;
            }
        },

        selectItem(id, check) {
            let index = this.selectedItems.indexOf(id);
            if (typeof check === 'undefined') {
                check = index === -1;
            }

            if (check) {
                // Doesn't selected
                this.selectedItems.push(id);
            } else {
                this.selectedItems.splice(index, 1);
            }
        },

        selectItems(ids) {
            for (const id of ids) {
                this.selectItem(id);
            }
        },

        getSelectedItems() {
            return this.selectedItems;
        },

        setCountPerPage(value) {
            this.entitiesPerPage = value;
            this.loadData();
        },

        /**
         * Select all items
         *
         * @return void
         */
        selectAll() {
            let checkboxes = this.$el.querySelectorAll(".select-item");
            let isChecked = this.$el.querySelector(".select-all").checked;
            for (const checkbox of checkboxes) {
                let value = checkbox.getAttribute('value')
                this.selectItem(parseInt(value), isChecked);
            }
        },

        more() {
            if (this.entitiesPerPage < this.totalCount) {
                this.entitiesPerPage += this.moreCount;
                this.loadData();
            }
        },

        updateEntity() {
            this.onUpdateEntity = true;
        },

        setCurrentItem(id) {
            this.currentItem = id;
        },

        /**
         * Return a value of the current item
         */
        getCurrentItemValue(value) {
            if (this.currentItem == 0) {
                return null;
            }
            let result = null;

            this.entities.forEach((item, index) => {
                if (
                    typeof item[value] != "undefined" &&
                    item.id == this.currentItem
                ) {
                    result = item[value];
                }
            });

            return result;
        },

        getDataRouteParams() {
            let routeParams = {
                _format: "json",
                begin: this.begin - 1,
                count: this.entitiesPerPage,
                order: this.order,
                rev: this.reverse,
            };
            if (this.routeParams) {
                _.assignIn(routeParams, this.routeParams);
            }

            return routeParams;
        },
    },
};
</script>

<template>
    <div class="data-list">
        <slot :entities="entities" :filters="filters" v-on:change="sort" :countPerPage="entitiesPerPage"
            :loading="loading" :loadData="loadData" :sort="sort" :begin="begin" :end="end" :totalCount="totalCount"
            :pageCount="pageCount" :currentPage="currentPage" :selectAll="selectAll" :setCurrentItem="setCurrentItem"
            :currentItem="currentItem" :changeFilter="changeFilter" :selectItem="selectItem"
            :selectedItems="selectedItems" :updateEntity="updateEntity" :more="more" :reverse="reverse" :order="order">
            Use custom template</slot>
    </div>
</template>

<style>
[v-cloak] {
    display: none
}
</style>