/**
 * Tigris Sortable
 * A vanilla js sortable plugin
 */

export default class TigrisSortable {

    constructor(element, options) {
        this.element = element;
        this.defaultsOptions = {
            onSort: null,
            itemSelector: ':scope > div',
            axe: 'both',
            dragClass: 'drag',
            itemClass: 'sort-item'
        };

        this.options = { ...this.defaultsOptions, ...options };

        this.listItems = element.querySelectorAll(this.options.itemSelector);
        this.dragItem = null;
        // Observer
        this.observer = new MutationObserver(() => {
            this.domUpdated();
        });
        this.observer.observe(this.element, { attributes: false, childList: true, subtree: true });
        this.domUpdated();

        this.dragOverCallback = (dragOverEvt) => {
            this.onDragOver(dragOverEvt);
        };

        this.dragEndCallback = (dragEndEvt) => {
            this.onDragEnd(dragEndEvt);
        };
        // Init drag event
        for (const listItem of this.listItems) {
            listItem.addEventListener('drag', (evt) => {
                evt.stopPropagation();
            }, false);

            listItem.addEventListener('dragstart', (evt) => {
                evt.stopPropagation();
                this.dragItem = evt.target;
                // Limiting the movement type
                evt.dataTransfer.effectAllowed = 'move';
                evt.dataTransfer.setData('Text', this.dragItem.textContent);

                this.element.addEventListener('dragover', this.dragOverCallback, false);
                this.element.addEventListener('dragend', this.dragEndCallback, false);

            }, false);
        }
    }

    getElementVerticalCenter(el) {
        const rect = el.getBoundingClientRect();

        return (rect.bottom - rect.top) / 2;
    }

    getElementHorizontalCenter(el) {
        const rect = el.getBoundingClientRect();

        return (rect.right - rect.left) / 2;
    }

    getMouseOffset(evt) {
        const targetRect = evt.target.getBoundingClientRect()
        const offset = {
            x: evt.pageX - targetRect.left,
            y: evt.pageY - targetRect.top
        }
        return offset
    }

    domUpdated() {
        this.listItems = this.element.querySelectorAll(this.options.itemSelector);
        for (const item of this.listItems) {
            if (!item.draggable) {
                item.draggable = true;
                item.classList.add(this.options.itemClass);
            }

        }
    }

    onDragOver(evt) {
        evt.preventDefault();
        evt.stopPropagation();
        evt.dataTransfer.dropEffect = 'move';
        let target = evt.target;
        if (!this.isValidItem(target)) {
            return;
        }

        const middleX = this.getElementHorizontalCenter(target);
        const middleY = this.getElementVerticalCenter(target);
        const offset = this.getMouseOffset(evt);

        if (this.options.axe == 'x') {
            if (offset.x > middleX) {
                target.parentNode.insertBefore(this.dragItem, target.nextSibling);
            } else {
                target.parentNode.insertBefore(this.dragItem, target);
            }
        } else if (this.options.axe == 'y') {
            if (offset.y > middleY) {
                target.parentNode.insertBefore(this.dragItem, target.nextSibling);
            } else {
                target.parentNode.insertBefore(this.dragItem, target);
            }
        } else {
            if (offset.x > middleX || offset.y > middleY) {
                target.parentNode.insertBefore(this.dragItem, target.nextSibling);
            } else {
                target.parentNode.insertBefore(this.dragItem, target);
            }
        }

        setTimeout(() => {
            this.dragItem.classList.add(this.options.dragClass);
        }, 0)
    }

    onDragEnd(evt) {
        evt.preventDefault();
        evt.stopPropagation();

        this.dragItem.classList.remove(this.options.dragClass);

        this.element.removeEventListener('dragover', this.dragOverCallback, false);
        this.element.removeEventListener('dragend', this.dragEndCallback, false);

        if (this.options.onSort !== null) {
            this.options.onSort();
        }
    }

    isValidItem(item) {
        for (const listItem of this.listItems) {
            if (listItem.isEqualNode(item) && item.parentNode.isEqualNode(this.dragItem.parentNode)) {
                return true;
            }
        }

        return false;
    }
}