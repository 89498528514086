<template>
    <div class="form-check form-switch">
        <input type="checkbox" class="form-check-input" :id="id" v-on:change="change" v-model="isChecked">
        <label class="form-check-label" :for="id">
            <small>{{ label }}</small>
        </label>
    </div>
</template>

<script>
export default {
    delimiters: ['${', '}'],
    props: {
        id: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: false,
            default: '',
        },
        route: {
            type: String,
            required: true,
        },
        routeParams: {
            type: Object,
            required: false,
            default: () => { return {} },
        },
        checked: {
            type: Boolean,
            required: false,
            default: false,
        }
    },

    data() {
        return {
            href: '',
            isChecked: this.checked,
        };
    },

    mounted: function () {
        this.href = Routing.generate(this.route, this.routeParams);
    },

    methods: {
        change() {
            this.$http.post(this.href).then(response => {

            });
        }
    }
};
</script>