<script>
export default {
    inject:['countPerPage', 'setCountPerPage'],
    data () {
        return {
            values: [30, 50, 100, 200],
            value: 30,
        };
    },
    created () {
        this.value = this.countPerPage;
    },
    methods: {
        change () {
            this.$emit("change", this.value);
            this.setCountPerPage(this.value);
        },
    },
}
</script>

<template>
    <select class="form-control" v-on:change="change" v-model="value"><option v-for="value in values" :key="value" :value="value">{{ value }}</option></select>
</template>