<script>
export default {
    delimiters: ["${", "}"],
    data() {
        return {
            newCounter: 0,
            loadNew: true,
            notifications: [],
            toasts: [],
        };
    },

    created() {
        this.loadNew = false;
        Tigris.eventManager().addListener("ajax-cron", (params, data) => {
            if (typeof data.notifications === "undefined") {
                return;
            }
            this.newCounter += data.notifications.length;
            if (data.notifications.length > 0) {
                data.notifications.forEach((notification) => {
                    this.notifications.unshift(notification);
                    this.toasts.unshift(notification);
                });

                setTimeout(() => {
                    if (bootstrap !== undefined) {
                        const toast = new bootstrap.Toast(document.querySelector('#notifications .toast:not(.hide)'))
                        toast.show()
                    }

                }, 500);
            }
        });

        this.loadLast();
    },

    methods: {
        loadLast() {
            let url = Routing.generate("tigris_base_notification_last");
            this.$http.get(url).then((response) => {
                this.newCounter = response.data.unreadCount;
                response.data.notifications.forEach((notification) => {
                    this.notifications.push(notification);
                });
            });
        },

        read() {
            if (this.newCounter == 0) {
                return;
            }

            const url = Routing.generate("tigris_base_notification_read");
            this.$http.get(url).then((response) => {
                this.newCounter = 0;
            });
        },
    },
};
</script>

<template>
    <div id="notifications" class="dropdown">
        <button type="button" id="notification-button" v-on:click="read" class="dropdown-toggle dropdown-toggle-split"
            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fa-bell" :class="'fa' + iconStyle"></i>
            <slot></slot>
            <span class="badge bg-primary" v-if="newCounter > 0">{{
            newCounter
        }}</span>
        </button>
        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="notification-button">
            <div class="d-flex justify-content-center" v-if="loadNew">
                <div class="spinner-grow text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>

            <ul class="list-group notification-list list-group-flush" v-if="!loadNew">
                <li v-for="notification in notifications.slice(0, 5)" :key="notification.id" class="list-group-item"
                    :class="{ unread: !notification.readed }">
                    <div class="d-flex">
                        <div class="flex-shrink-0" v-if="notification.icon">
                            <img :src="'/' + notification.icon" class="me-3 notification-icon"
                                alt="notification.title" />
                        </div>
                        <div class="flex-grow-1 ms-3">
                            <h5 class="mt-0">{{ notification.title }}</h5>
                            <span class="text-muted">{{ $filters.formatDate(notification.date, "fromNow") }}</span>
                            <div class="my-2">{{ notification.content }}</div>
                            <a v-for="button in notification.buttons" :key="button.label" class="btn btn-sm btn-primary"
                                :href="button.url">
                                {{ button.label }}
                            </a>
                        </div>
                    </div>
                </li>
            </ul>
            <div class="container">
                <button-action route="tigris_base_notification_list" class="btn btn-primary d-block">
                    <i class="fa-ellipsis-h" :class="'fa' + iconStyle"></i>
                </button-action>
            </div>

        </div>

        <div class="toast notification" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="8000"
            v-for="notification in toasts" :key="notification.id">
            <div class="toast-header">
                <img :src="notification.icon" class="rounded me-2" :alt="notification.title" v-if="notification.icon" />
                <strong class="me-auto">{{ notification.title }}</strong>
                <button type="button" class="ms-2 mb-1 close" data-bs-dismiss="toast" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="toast-body">
                <div>{{ notification.content }}</div>
                <a v-for="button in notification.buttons" :key="button.label" class="btn btn-sm btn-primary"
                    :href="button.url">{{ button.label }}</a>
            </div>
        </div>
    </div>
</template>

<style scoped>
.dropdown-menu {
    min-width: 400px;
}

#notification-button {
    border: none;
    background: none;
    margin: 0 0.5em;
}
</style>