/**
 * Event object
*/
export default class TigrisEvent {
    constructor() {
        this.listeners = [];
    }

    addListener(eventName, func) {
        this.listeners.push({ event: eventName, func: func, oneShot: false });
        return this.listeners.length - 1;
    }

    addOneShotListener(eventName, func) {
        this.listeners.push({ event: eventName, func: func, oneShot: true });
        return this.listeners.length - 1;
    }

    removeListener(id) {
        this.listeners.splice(id, 1, null);
    }

    removeListeners(eventName) {
        for (i = 0; i < this.listeners.length; i++) {
            if (this.listeners[i].event == eventName) {
                this.listeners.splice(i, 1, null);
            }
        }
    }

    trigger(name, params, data, callback) {
        let oneShotListeners = [];
        for (var i = 0; i < this.listeners.length; i++) {
            if (this.listeners[i] && this.listeners[i].event == name) {
                this.listeners[i].func(params, data);
                if (this.listeners[i].oneShot) {
                    oneShotListeners.push(i);
                }
            }
        }

        let self = this;
        oneShotListeners.forEach(function (value) {
            self.listeners.splice(value, 1, null);
        });

        if (typeof callback == 'function') {
            callback(data);
        }
    }
}