import './tinymce-file';

import 'tinymce/plugins/advlist';
import 'tinymce/plugins/code';
import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/emoticons/js/emojis';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';
import 'tinymce/plugins/quickbars';
import 'tinymce/plugins/media';
import 'tinymce/plugins/image';
import 'tinymce/plugins/wordcount';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/models/dom/model'
import 'tinymce/skins/ui/tinymce-5/skin.css';
import 'tinymce/icons/default';
import 'tinymce-i18n/langs/fr_FR'

const TINY_MCE_TOOLS_MAXI = {
    toolbar: 'undo redo | bold italic underline | alignleft aligncenter alignright | outdent indent | forecolor | tigrisFile',
    menu: {
        edit: { title: 'Edit', items: 'undo redo | cut copy paste | selectall | searchreplace' },
        view: { title: 'View', items: 'code | visualaid visualchars visualblocks | spellchecker | preview fullscreen' },
        insert: { title: 'Insert', items: 'tigrisFile link media template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime' },
        format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript codeformat | formats blocks blockformats align | forecolor backcolor | removeformat' },
        tools: { title: 'Tools', items: 'spellchecker spellcheckerlanguage | code wordcount' },
        table: { title: 'Table', items: 'inserttable | cell row column | tableprops deletetable' },
    },
    menubar: "edit view insert format tools table",
    quickBars: "bold italic underline | quicklink h2 h3 blockquote quicktable",
    plugins: ['advlist', 'code', 'emoticons', 'link', 'lists', 'table', 'tigrisFile', 'image', 'media', 'wordcount'],
    language: 'fr_FR',
    promotion: false,
    contextMenu: 'table',
    contextToolbar: 'table',
};

const TINY_MCE_TOOLS_MINI = {
    toolbar: 'undo | bold italic underline' + (Tigris.options.wysiwygTools.colors ? ' | forecolor backcolor' : ''),
    menu: {
    },
    menubar: "",
    quickBars: "undo | bold italic underline" + (Tigris.options.wysiwygTools.colors ? ' | forecolor backcolor' : ''),
    plugins: '',
    language: 'fr_FR',
    height: 200,
    promotion: false,
    contextMenu: '',
    contextToolbar: '',
};

export { TINY_MCE_TOOLS_MAXI, TINY_MCE_TOOLS_MINI }; 