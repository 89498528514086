import "@porscheofficial/cookie-consent-banner/dist/cookie-consent-banner/cookie-consent-banner.esm.js"

class TigrisRGPD {
    constructor() {
        const cookieConsentBannerElement = document.querySelector("cookie-consent-banner");

        if (null === cookieConsentBannerElement) {
            return;
        }

        console.log(cookieConsentBannerElement.getAttribute("data-google-tag-manager-id"))
        this.googleTagManagerId = cookieConsentBannerElement.getAttribute("data-google-tag-manager-id");
        cookieConsentBannerElement.availableCategories = [
        {
            description:
            "Permettent de naviguer et d'utiliser les fonctions de base et de stocker les préférences.",
            key: "technically_required",
            label: "Cookies nécessaires au fonctionnement technique",
            isMandatory: true,
        },
        {
            description:
            "Permettent de déterminer comment les visiteurs interagissent avec notre service afin d'améliorer l'expérience utilisateur.",
            key: "analytics",
            label: "Cookies d'analyse",
        },
        ];

        window.addEventListener("cookie_consent_preferences_restored", () => this.loadTagManager());
        window.addEventListener("cookie_consent_preferences_updated", () => this.loadTagManager());
    }
    loadTagManager() {
        if (typeof google_tag_manager !== "undefined" || this.googleTagManagerId === null) return; // load only once
        const gTags = function (w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({
            "gtm.start": new Date().getTime(),
            event: "gtm.js",
          });
          let f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != "dataLayer" ? "&l=" + l : "";
          j.async = true;
          j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
          f.parentNode.insertBefore(j, f);
        };
    
        gTags(window, document, "script", "dataLayer", this.googleTagManagerId);
      }
};

export default TigrisRGPD;