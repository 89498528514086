<script>
export default {
    data () {
        return {
            icon: "sort",
            reverse: false,
        };
    },
    inject: ['order'],
    props: {
        label: {
            type: String,
            default: "Label",
        },

        value: {
            type: String,
            default: null,
        },
    },
    methods: {
        sort () {
            if (!this.value) {
                return;
            }
            if (this.reverse) {
                this.icon = "caret-up";
            } else {
                this.icon = "caret-down";
            }

            this.reverse = !this.reverse;
            this.$emit("change", this.value);
        },
    },

    watch: {
        order (val) {
            if (val != this.value) {
                this.icon = "sort";
                this.reverse = false;
            }
        },
    },

}
</script>

<template>
    <span v-on:click="sort()" class="sortable-col"><span v-html="label"></span> <i :class="'fa'+ iconStyle +' fa-'+icon"></i></span>
</template>