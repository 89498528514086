import TigrisEvent from './tigris-event';
import TigrisAlert from './tigris-alert';
import TigrisSortable from './tigris-sortable.js';
import Translator from 'bazinga-translator';
import TigrisEvents from './tigris-events'
import 'v-calendar/dist/style.css';
import messages from './../../translations/messages.fr.json'
import TigrisRGPD from './tigris-rgpd';

import TigrisFileManager from '../vue/TigrisFileManager'
import { TigrisConfig } from '../../../../../config/packages/tigris_config.js';

import {AlertMessage, AlertType} from '../ts/component/alert-message'
import {Form} from '../ts/component/form'

class TigrisBase {
    constructor(options) {
        this.setOptions(options);
        this.EVENT_AJAX_LOADED = 'ajax_loaded';
        this.EVENT_FORM_POST = 'form_post';
        this.EVENT_WYSIWYG_LOAD = 'wysiwyg_load';
        this.EVENT_WYSIWYG_LOADED = 'wysiwyg_loaded';
        this.eventObj = new TigrisEvent;
        this.alertObj = new TigrisAlert;
        this.rgpd = new TigrisRGPD;
        this.formManagers = {};
        this.formLoaded = false;
        this.tigrisFormObj = null;
        this.cronEnabled = false;
    }

    setOptions(options) {
        this.defaultsOptions = {
            wysiwygEditor: "tinymce",
            wysiwygTools: {
                colors: false,
            },
            charts: {
                colors: [
                    { bg: "#1abc9c", bd: "#1abc9c" },
                    { bg: "#9b59b6", bd: "#9b59b6" },
                    { bg: "#f39c12", bd: "#f39c12" },
                    { bg: "#2980b9", bd: "#2980b9" },
                    { bg: "#2ecc71", bd: "#2ecc71" }, // Magenta
                    { bg: "#e74c3c", bd: "#e74c3c" }, // Orange
                    { bg: "#34495e", bd: "#34495e" }, // Water
                    { bg: "rgba(143, 68, 173, 1)", bd: "rgba(143, 68, 173, 1)" }, // Violet
                    { bg: "rgba(23, 160, 134, 1)", bd: "rgba(23, 160, 134, 1)" }, // Green
                    { bg: "rgba(224, 97, 97, 1)", bd: "rgba(224, 97, 97, 1)" }, // Pink
                    { bg: "rgba(53, 70, 93, 1)", bd: "rgba(53, 70, 93, 1)" }, // Dark
                ]
            }
        };

        this.options = { ...this.defaultsOptions, ...options };
    }

    enableCron() {
        this.cronEnabled = true;
        this.onCron();
    }

    onCron() {
        if (!this.cronEnabled) {
            return;
        }
        let xhttp = new XMLHttpRequest();
        xhttp.onload = () => {
            if (xhttp.status == 200) {
                let response = JSON.parse(xhttp.responseText);

                document.dispatchEvent(new Event('ajax-cron'));
                this.cron();
            }
        };

        let url = Routing.generate('tigris_base_app_ajaxcron');
        url += ('?t=' + Date.now());
        xhttp.open('GET', url, true);
        xhttp.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        xhttp.send();
    }

    cron() {

        if (!this.cronEnabled) {
            return;
        }

        setTimeout(() => {
            this.onCron();
        }, 20000);
    }

    eventManager() {
        return this.eventObj;
    }

    alert() {
        return this.alertObj;
    }

    init() {
        console.log('%c Cette application a été réalisée avec l\'outil Tigris d\'Oviglo (www.oviglo.fr)', 'color: #5180b3; font-size: 16px');

        if (typeof cookieBar !== 'undefined') {
        }

        this.loadSort();

        // Reinit form widgets on collection add
        document.addEventListener('form-collection:field-added', (event) => {
            const detail = event.detail;

            if (detail.element === null) {
                return;
            }

            this.loadForm(detail.element);
        })

        // Réinit form widgets on ajax load
        document.addEventListener(TigrisEvents.AJAX_LOADED, (e) => {
            if (e.detail.element === null) {

                return;
            }
            //this.loadForm(e.detail.element, function (element) { });
            this.loadSort(e.detail.element);

        });

        document.addEventListener("tigris-data-list:loaded", () => {
            // Copy content
            let copyElements = document.querySelectorAll('[data-copy]');
            copyElements.forEach(element => {
                element.addEventListener('click', () => {
                    this.copy(element.attributes['data-copy'].value);
                });
            });
        });

        document.addEventListener(TigrisEvents.VUE_APP_MOUNTED, e => {
            const forms = document.getElementsByTagName("form")
            if (forms.length > 0) {
                for (const key in forms) {
                    if (typeof forms[key] == 'object') {
                        this.loadForm(forms[key]);
                    }
                } 
            }
        })

        this.initTranslation()
    }

    loadForm(element, callback) {
        if (typeof element === 'undefined' || element === null) {
            return;
        }
        new Form(element).init();

        /*if (null === this.tigrisFormObj) {
            import('./tigris-form.js').then(TigrisForm => {
                this.tigrisFormObj = TigrisForm.default;
                let tigrisFormObj = this.tigrisFormObj;
                let tigrisForm = new tigrisFormObj(element);
                tigrisForm.init();
                if (typeof callback === 'Function') {
                    callback(tigrisForm);
                }
            });
        } else {
            let tigrisFormObj = this.tigrisFormObj;
            let tigrisForm = new tigrisFormObj(element);
            tigrisForm.init();
            if (typeof callback === 'function') {
                callback(tigrisForm);
            }
        }*/

    }

    /**
     * Init sortable elements
     * @param {*} element 
     */
    loadSort() {

        let spreadIndex = function (el) {
            let liItems = el.querySelectorAll('li');
            for (const [index, item] of liItems.entries()) {
                item.querySelector('input[type="hidden"]').value = index;
                let children = item.querySelectorAll('ul');
                if (children.length > 0) {
                    for (const child of children) {
                        spreadIndex(child);
                    }

                }
            }
        };

        let sortableEl = document.querySelector('.sortable');
        if (sortableEl !== null) {
            new TigrisSortable(sortableEl, {
                onSort: () => {
                    spreadIndex(sortableEl);
                },
                axe: 'y',
                itemSelector: ':scope li'
            });
        }
    }

    copy(content) {
        navigator.clipboard.writeTexte(content).then(() => {
            this.alert().success(content).life(2000);
        });
    }

    createNode(text) {
        var node = document.createElement('pre');
        node.style.width = '1px';
        node.style.height = '1px';
        node.style.position = 'fixed';
        node.style.top = '5px';
        node.textContent = text;
        return node;
    }

    openFileSelectorDialog() {
        let promise = new Promise((resolve, reject) => {
            let event = new CustomEvent(TigrisEvents.OPEN_DIALOG, {
                detail: {
                    size: 'full',
                    insideComponent: TigrisFileManager, onLoaded: (dialog) => {
                        resolve(dialog);
                    }
                }
            })

            document.dispatchEvent(event);
        })

        return promise;
    }

    initTranslation() {
        this.addTranslationJson(messages)
    }

    addTranslationJson(data) {
        let translations = data.translations
        let local = data.locale
        for (const key in translations) {
            if (Object.hasOwnProperty.call(translations, key)) {
                const element = translations[key];

                this.addTranslation(key, element, local)
            }
        }
    }

    trans(str, params) {
        return Translator.trans(str, params);
    }

    /**
     * 
     * @param {String} key 
     * @param {String} trans 
     * @param {String} local 
     */
    addTranslation(key, trans, local) {
        if (local === null) {
            local = "fr";
        }

        if (typeof trans === 'string') {
            Translator.add(key, trans, local);
            return
        }

        for (const subKey in trans) {
            if (Object.hasOwnProperty.call(trans, subKey)) {
                const element = trans[subKey];
                this.addTranslation(key + '.' + subKey, element)
            }
        }
    }
}

const Tigris = new TigrisBase(TigrisConfig);
window.Tigris = Tigris;
export { Tigris };