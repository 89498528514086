<script>
var Editor = require("@tinymce/tinymce-vue").default;
const TINY_MCE_TOOLS_MAXI =
    require("../../js/wysiwyg/tinymce-tools").TINY_MCE_TOOLS_MAXI;
const TINY_MCE_TOOLS_MINI =
    require("../../js/wysiwyg/tinymce-tools").TINY_MCE_TOOLS_MINI;

module.exports = {
    delimiters: ["${", "}"],
    template:
        '<editor :name="name" :init="toolsData" v-model="inputVal" api-key="eahx6tmmw0asrlv8u5zmjej176ge440y8up4t3inlzx3xo77"></editor>',
    props: {
        name: {
            type: String,
            required: true,
        },
        modelValue: {
            required: true,
        },
        tools: {
            type: String,
            required: false,
            default: "maxi",
        },
        inline: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: () => {
        return {
            toolsData: TINY_MCE_TOOLS_MAXI,
        };
    },

    created: function () {
        if (this.tools === "mini") {
            this.toolsData = TINY_MCE_TOOLS_MINI;
        }

        this.toolsData.inline = this.inline;

        if (this.inline) {
            this.toolsData.content_style = [];
        }
    },
    computed: {
        inputVal: {
            get() {
                return this.modelValue;
            },
            set(val) {
                this.$emit("update:modelValue", val);
            },
        },
    },
    components: {
        editor: Editor,
    },
};
</script>