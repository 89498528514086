<script>
import { debounce } from '../../ts/utils'

export default {
    props: {
        route: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        initValue: {
            type: Array,
            required: false,
            defaults: []
        }
    },

    data: () => {
        return {
            entities: [],
            search: '',
            selected: [],
            selectedIds: [],
            searchResult: [],
        }
    },

    mounted() {
        this.loadData()
        for (const value of this.initValue) {
            this.selectedIds.push(value.id)
            this.selected.push(value)
        }
    },

    methods: {
        loadData() {
            const url = Routing.generate(this.route, { '_format': 'json', s: this.search, count: 10 })
            this.searchResult = []
            this.entities = []
            this.$http.get(url).then((response) => {
                for (const entity of response.data.entities) {
                    this.searchResult.push(entity)

                    if (null === this.getSelectedItem(entity.id)) {
                        this.entities.push(entity)
                    }

                }
            })
        },

        selectItem(id) {
            for (let i = 0; i < this.entities.length; i++) {
                const entity = this.entities[i]
                if (entity.id === id) {
                    this.selected.push(entity)
                    this.selectedIds.push(entity.id)
                    this.entities.splice(i, 1)

                    return

                }
            }
        },

        removeItem(id) {
            for (let i = 0; i < this.selected.length; i++) {
                const entity = this.selected[i]
                if (entity.id === id) {
                    this.selected.splice(i, 1)
                    this.selectedIds.splice(i)

                    // Check if item is in search result
                    if (null !== this.getSearchItem(id)) {
                        this.entities.push(entity)
                    }

                    return
                }
            }
        },

        getSearchItem(id) {
            for (const entity of this.searchResult) {
                if (entity.id === id) {
                    return entity
                }
            }

            return null
        },

        getSelectedItem(id) {
            for (const entity of this.selected) {
                if (entity.id === id) {
                    return entity
                }
            }

            return null
        }
    },

    watch: {
        search: {
            handler: debounce(function () {
                this.loadData()
            }, 500)
        }
    }
}
</script>

<template>
    <div class="dynamic-list-select">
        <div class="selected-items">
            <span>{{ $filters.trans('selected_item') }}</span>
            <div class="form-control">
                <div v-for="entity in selected" :key="entity.id" class="item">
                    <div>{{ entity.name }}</div>
                    <button type="button" @click="() => { removeItem(entity.id) }"
                        :title="$filters.trans('btn.remove')"><span class="text-danger"
                            v-html="$filters.parseIcons('.icon-close')"></span></button>
                </div>
            </div>
        </div>
        <div class="choice-items">
            <input class="form-control mb-2" type="search" v-model="search" :placeholder="$filters.trans('search')">
            <div class="form-control">
                <div v-for="entity in entities" :key="entity.id" class="item">
                    <div>{{ entity.name }}</div>
                    <button type="button" @click="() => { selectItem(entity.id) }"
                        :title="$filters.trans('btn.add')"><span class="text-success"
                            v-html="$filters.parseIcons('.icon-plus')"></span></button>
                </div>
            </div>
        </div>
        <input v-for="selectedId in selectedIds" type="hidden" :name="name" :key="selectedId" :value="selectedId">
    </div>
</template>

<style scoped>
.dynamic-list-select {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    width: 100%;
    height: 180px;
}

.dynamic-list-select .choice-items {
    flex-grow: 1;
    padding-left: 4px;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
}

.dynamic-list-select .choice-items>div {
    overflow-y: auto;
    flex-grow: 1;
}

.dynamic-list-select .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dynamic-list-select button {
    border: none;
    background: none;
}

.dynamic-list-select .selected-items {
    flex-grow: 1;
    padding-right: 4px;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
}

.dynamic-list-select .selected-items>div {
    overflow-y: auto;
    flex-grow: 1;
}
</style>