<script>
export default {
    props: {
        name: {
            type: String,
        },
    },

    data() {
        return {
            args: '',
            output: '',
            loading: false,
        }
    },

    watch: {
        output() {
            setTimeout(() => {
                const consoleBody = this.$el.querySelector('.output');
                if (consoleBody !== null) {
                    consoleBody.scrollTo(0, consoleBody.scrollHeight)
                }
            }, 10);

        }
    },

    methods: {
        execute() {
            this.loading = true
            this.$http.post(Routing.generate('tigris_base_admin_uicommand_run'), {
                name: this.name,
                args: this.args,
            }).then(response => {
                this.output += response.data.output
                this.loading = false
            })
        },

        reset() {
            this.output = ''
        },

        updateArgs(args) {
            this.args = args
        }
    }
}
</script>

<template>
    <div>
        <slot :args="args" :execute="execute" :output="output" :reset="reset" :loading="loading"
            :updateArgs="updateArgs">
        </slot>
    </div>
</template>