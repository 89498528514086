<script>

import TigrisEvents from './../js/tigris-events'
import { Modal } from 'bootstrap'

export default {
    props: {
        route: {
            type: String,
        },
        routeParams: {
            type: Object,
            required: false,
            default: {},
        },

        json: {
            type: Boolean,
            required: false,
            default: true,
        },

        component: {
            type: Object,
            required: false,
            default: null,
        },

        size: {
            type: [String, null],
            required: false,
            default: 'lg',
        }
    },

    data() {
        return {
            modal: null,
            body: "",
            title: "",
            form: null,
        };
    },

    mounted() {
        this.modal = new Modal(this.$refs.modal, {
            keyboard: false
        });

        this.$refs.modal.addEventListener('hidden.bs.modal', event => {
            this.close();
        })

        this.$emit('created', this);
    },

    methods: {
        open() {
            this.body = '';
            this.load();
            this.modal.show();
        },

        close() {
            this.modal.hide()
            document.dispatchEvent(new CustomEvent(TigrisEvents.CLOSE_DIALOG, { detail: { element: this.$el } }))
        },

        load() {
            if (null !== this.component) {
                return
            }
            const url = Routing.generate(this.route, this.routeParams);
            this.$http.get(url).then((response) => {
                this.body = response.data;
            });
        },

        valid() {
            this.$emit('valid')
        },

        cancel() {
            this.close()
        },

        initContent() {

            document.dispatchEvent(new CustomEvent(TigrisEvents.AJAX_LOADED, { detail: { element: this.$el } }))

            const cancelBtn = this.$el.querySelector('[data-cancel]')
            if (null !== cancelBtn) {
                cancelBtn.addEventListener('click', (e) => {
                    e.preventDefault()
                    this.cancel()
                });
            }
            const closeBtn = this.$el.querySelector('[data-close]');
            if (null !== closeBtn) {
                closeBtn.addEventListener('click', (e) => {
                    e.preventDefault()
                    this.close()
                });
            }
            const validBtn = this.$el.querySelector('[data-valid]');
            if (null !== validBtn) {
                validBtn.addEventListener('click', (e) => {
                    e.preventDefault()
                    this.valid()
                });
            }

            this.form = this.$el.querySelector('form:not(.no-ajax, [no-ajax="no-ajax"])');
            if (null !== this.form) {
                this.initForm();
            }

            document.dispatchEvent(new CustomEvent(TigrisEvents.DIALOG_CONTENT_LOADED, { detail: { element: this.$el } }))
        },

        initForm() {
            Tigris.loadForm(this.form);
            this.form.addEventListener('submit', (event) => {
                event.preventDefault();
                event.stopPropagation();

                document.dispatchEvent(new CustomEvent(TigrisEvents.DIALOG_FORM_SUBMIT, { detail: { form: this.form } }))

                let formData = new FormData(this.form);
                const submitButton = this.form.querySelector('button[type="submit"]');
                if (null !== submitButton) {
                    submitButton.setAttribute('disabled', true);
                }

                const methodInput = this.form.querySelector('[name="_method"]');
                const method = methodInput != null ? methodInput.value : this.form.getAttribute('method');

                if ('POST' !== method.toUpperCase()) {
                    formData = new URLSearchParams(formData).toString();
                }

                this.$http({
                    method: method,
                    url: this.form.getAttribute('action'),
                    data: formData,
                }).then(response => {
                    if (response.data.status === 'success' && response.data.status) {
                        this.$emit('formSaved')
                        this.close();
                    } else {
                        this.body = response.data;
                    }
                });
            });
        }
    },

    watch: {
        body: {
            handler(val) {
                if (val) {
                    this.$nextTick(() => {
                        this.initContent()
                    })
                }
            },
        }

    }
};
</script>

<template>
    <div class="modal fade" tabindex="-1" ref="modal" :id="'tigris-dialog-' + (Math.random() * 10000)">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable"
            :class="size !== null ? 'modal-' + size : ''">
            <div class="modal-content" v-html="body" v-if="body !== ''">

            </div>
            <div class="modal-content" v-else-if="component">
                <component :is="component" @valid="() => valid()" @cancel="() => cancel()"
                    v-bind="{ routeParams: routeParams }"></component>
            </div>
            <div class="modal-content" v-else>
                <div class="d-flex justify-content-center m-3">
                    <div class="spinner-grow text-primary" role="status"></div>
                </div>
            </div>

        </div>
    </div>
</template>