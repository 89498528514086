"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = parseIcons;
function parseIcons(value, iconStyle = '') {
    let regex = /\.([a-z]+)-([a-z0-9+-]+)/;
    return value.replace(regex, function (matche, style, type) {
        let icon = '';
        if (style == 'icon') {
            icon = 'fa' + iconStyle + ' fa-' + type;
        }
        else if (style == 'brand') {
            icon = 'fab fa-' + type;
        }
        return '<i class="' + icon + '"></i> ';
    });
}
