"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = debounce;
function debounce(fn, wait) {
    let timer = null;
    return function (...args) {
        if (timer) {
            clearTimeout(timer); // clear any pre-existing timer
        }
        timer = setTimeout(() => {
            fn.apply(this, args); // call the function if time expires
        }, wait);
    };
}
