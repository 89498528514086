import tinymce from 'tinymce';


tinymce.PluginManager.add('tigrisFile', function (editor) {

    editor.ui.registry.addButton('tigrisFile', {
        text: 'Insérer un fichier',
        onAction() {

            Tigris.openFileSelectorDialog().then(dialog => {
                dialog.onValid = () => {
                    let selected = dialog.DOMElement.querySelectorAll('input.select-file:checked');
                    let event = new CustomEvent('tigris_closeFileManager');
                    document.dispatchEvent(event);

                    for (const check of selected) {
                        const type = check.closest('.file-item').getAttribute('data-type');
                        const name = check.closest('.file-item').getAttribute('data-name');
                        const alt = check.closest('.file-item').getAttribute('data-alt');
                        const path = check.closest('.file-item').getAttribute('data-path');
                        const size = check.closest('.file-item').getAttribute('data-size');
                        const id = check.closest('.file-item').getAttribute('data-id');
                        check.checked = false;

                        if (type === 'image') {
                            let content = '<img alt="' + alt + '" src="' + path + '" data-id="' + id + '">';
                            editor.insertContent(content);
                        } else if (type === 'audio' || type === 'video') {
                            let preview = null;
                            let figure = document.createElement('figure');
                            figure.setAttribute('data-id', id);
                            let figcaption = document.createElement('figcaption');
                            figcaption.innerHTML = name;
                            figure.append(figcaption);
                            preview = document.createElement(type);
                            preview.setAttribute('src', path);
                            preview.setAttribute('controls', true);
                            figure.append(preview);

                            editor.insertContent(figure.outerHTML);
                        } else if (type === 'file' || type === 'application') {
                            let node = document.createElement('a');
                            node.innerHTML = name;
                            node.setAttribute('class', 'download-file');
                            node.setAttribute('href', path);
                            node.setAttribute('data-id', id);
                            if (size != 0) {
                                node.setAttribute('data-size', size);
                            }

                            editor.insertContent(node.outerHTML);
                        }

                    };

                    dialog.close()
                }

            })
        }
    })
    return {
        getMetadata: () => ({
            name: 'Tigris File',
            url: '',
        })
    };
});