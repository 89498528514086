<script>
import Dropzone from "dropzone";

export default {
    props: {
        label: {
            required: false,
            type: String,
            defaults: null,
        },
        name: {
            required: false,
            type: String,
            default: "files[]",
        },

        folder: {
            required: false,
            type: Number,
            defaults: null,
        },

        removeFilesOnComplete: {
            required: false,
            type: Boolean,
            defaults: false,
        },

        showPreview: {
            type: Boolean,
            required: false,
            default: true,
        },

        autoUpload: {
            type: Boolean,
            required: false,
            default: true,
        },

        uploadRoute: {
            type: String,
            required: false,
            default: 'tigris_base_file_upload',
        },

        uploadRouteParams: {
            type: Object,
            required: false,
            default: {}
        }
    },

    data() {
        return {
            fileIds: [],
        };
    },

    computed: {
        dropzoneSettings() {
            let defaultValues = {
                thumbnailWidth: 200,
                thumbnailHeight: 200,
                maxFilesize: 2048,
                url: Routing.generate(this.uploadRoute, {
                    folder: this.folder,
                    ...this.uploadRouteParams,
                }),
                autoProcessQueue: this.autoUpload,
            };

            if (!this.showPreview) {
                defaultValues.previewTemplate = "<div></div>"
            }

            return defaultValues;
        },
    },
    mounted() {
        this.dropzone = new Dropzone(
            this.$refs.dropzoneElement,
            this.dropzoneSettings
        );

        // Events
        this.dropzone.on("addedfile", (file) => {
            this.$emit("uploadAddedFile", file);
        });

        this.dropzone.on("success", (file, response) => {
            if (response.status === "error") {
                Tigris.alert()
                    .create(response.message, response.status)
                    .life(3000);

                return;
            }
            this.fileIds.push(response.id);
            this.successMessage = response.message;
            this.$emit("uploadSuccess", file);
        });

        this.dropzone.on("queuecomplete", () => {
            if (this.removeFilesOnComplete) {
                this.dropzone.removeAllFiles();
            }

            Tigris.alert().create(this.successMessage, "success").life(3000);

            this.$emit("uploadComplete", this.getLoadedFileIds());
        });
    },

    methods: {
        getLoadedFileIds() {
            return this.fileIds;
        },

        openLoadFileDialog() {
            this.dropzone.hiddenFileInput.click()
        },

        processUpload() {
            this.dropzone.processQueue()
        },

        hasQueuedFiles() {
            return this.dropzone.getQueuedFiles().length > 0
        },

        reset() {
            this.dropzone.removeAllFiles()
        },
    },

    watch: {
        folder(val) {
            this.dropzone.options.url = Routing.generate(
                this.uploadRoute,
                {
                    folder: val,
                    ...this.uploadRouteParams,
                }
            );
        },
    },
};
</script>

<template>
    <div ref="dropzoneElement" class="dropzone">
        <div class="dz-message">
            {{ label }}
        </div>
    </div>
</template>