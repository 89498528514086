<script>
import ListTreeItem from "./base/ListTreeItem.vue";

export default {
    props: {
        route: {
            type: String,
            required: false,
            default: "",
        },

        routeSorting: {
            type: String,
            required: false,
            default: "",
        },

        filter: {
            type: String,
            required: false,
            default: "folder",
        },

        rootId: {
            type: Number,
            required: false,
            default: 0,
        },

        rootName: {
            type: String,
            required: false,
            default: null,
        }
    },

    data() {
        return {
            items: [],
            loading: false,
            currentPath: "",
            selected: null,
            isAjax: true,
        };
    },

    inject: ["changeFilter"],
    emits: ['changeFolder'],

    components: {
        ListTreeItem,
    },

    created() {
        this.loadTree();
    },

    methods: {
        loadItems(parentId, callback) {
            this.$emit('changeFolder', parentId);
            this.selected = parentId;
            if (typeof callback === "function") {
                callback(this.items);
            }
        },

        loadTree() {
            const url = Routing.generate(this.route, {
                _format: "json",
            });

            this.$http.get(url).then((response) => {
                if (response.status === 200) {
                    this.items = response.data.entities;
                    if (null !== response.data.selectedFolder) {
                        this.selected = response.data.selectedFolder;
                        this.loadItems(response.data.selectedFolder);
                    }

                }
            })
        },

        insertChildren(parentId, children) {

            if (parentId === null) { // For root
                this.items = children;
                return;
            }

            let parent = this.searchItem(parentId, this.items)

            if (parent !== null) {
                parent.children = children;
            }
        },

        searchItem(id, items) {
            for (const item of items) {
                if (item.id === id) {
                    return item;
                }

                if (item.children) {
                    const result = this.searchItem(id, item.children)

                    if (result !== null) {
                        return result;
                    }
                }
            }

            return null;
        }
    },
};
</script>

<template>
    <slot :selected="selected" :loadItems="loadItems" :currentPath="currentPath" :loadTree="loadTree"></slot>
    <ul class="list-tree">
        <list-tree-item :items="items" :rootName="rootName" v-on:open="loadItems" :selected="selected"></list-tree-item>
    </ul>
</template>